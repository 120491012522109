import React from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import PrismicLink from 'components/PrismicLink';
import prismicImageShape from 'shapes/prismicImageShape';
import prismicLinkShape from 'shapes/prismicLinkShape';
import { getImgAlt } from 'utils/functions';
import Button from 'components/Button';

const propTypes = {
  appLogo: prismicImageShape.isRequired,
  downloadButtonText: PropTypes.string.isRequired,
  buttonStyle: PropTypes.string,
  downloadLink: prismicLinkShape.isRequired,
};

const AppLogo = styled.img`
  height: 2rem;
`;

const LogoContainer = styled.div`
  display: flex;
  justify-content: space-between;
  height: inherit;
  align-items: center;
  padding: 0 1rem;
`;

function DownloadSection({
  appLogo,
  downloadButtonText,
  downloadLink,
  buttonStyle = 'Link',
}) {
  const ButtonComponent =
    buttonStyle && buttonStyle === 'Button' ? Button : 'div';

  return (
    <PrismicLink
      link={downloadLink}
      internalAs={Link}
      internalProps={{ as: Link }}
      externalAs='a'
      externalProps={{
        target: '_blank',
      }}
    >
      <LogoContainer>
        {appLogo && <AppLogo alt={getImgAlt(appLogo)} src={appLogo.url} />}
        <ButtonComponent size='sm'>{downloadButtonText}</ButtonComponent>
      </LogoContainer>
    </PrismicLink>
  );
}

DownloadSection.propTypes = propTypes;

export default DownloadSection;
