import React from 'react';
import PropTypes from 'prop-types';
import { prop, theme } from 'styled-tools';
import first from 'lodash/first';
import get from 'lodash/get';
import styled from 'styled-components/macro';
import PrismicLink from 'components/PrismicLink';
import { graphql, Link, StaticQuery } from 'gatsby';
import { withPreview } from 'gatsby-source-prismic-graphql';
import linkShape from 'shapes/prismicLinkShape';
import imageShape from 'shapes/prismicImageShape';
import richTextShape from 'shapes/prismicRichTextShape';
import { between, down, up } from 'styled-breakpoints';
import { P } from '../Typography';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import RichText from 'components/RichText';
import FooterLinkList from './FooterLinkList';
import AppDownload from 'components/AppDownload';
import Container from 'react-bootstrap/Container';
import { getCurrentYear } from 'utils/functions';
import socialMediaShape from 'shapes/socialMediaShape';

const StyledFooter = styled.footer`
  background-color: ${theme('colors.dark')};
  color: ${theme('colors.white')};
  font-size: 0.875rem;
`;

const FooterContainer = styled(Container)`
  padding: 3.75rem 1.25rem;

  ${up('md')} {
    padding: 3.75rem 0;
  }
`;

const StyledRow = styled(Row)`
  margin-bottom: 3.75rem;
`;

const SectionTitle = styled(P)`
  font-weight: ${theme('fontWeights.default.semiBold')};
`;

const AppDownloadContainer = styled.div`
  ${down('sm')} {
    display: flex;
    flex-direction: row;
  }

  a {
    display: block;
    margin: 0;
    width: 100%;
  }

  img {
    max-width: none;
    width: inherit;
  }
`;

const CopyrightSection = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 0.75rem;
  justify-content: center;

  ${up('md')} {
    flex-direction: row;
  }

  > div {
    ${down('md')} {
      margin-bottom: 0.5rem;
    }
  }

  // Support "divider" label in prismic rich text for pipes between copyright
  .divider {
    padding: 0 0.3125rem;

    ${down('md')} {
      display: none;
    }
  }

  // If "finra" label is used for the FINRA link, style the link as blue.
  .finra {
    // Support class inside the a tag, on the a tag, as well as class wrapping a tag.
    &,
    a {
      color: ${theme('colors.azureBlue')};

      &:hover {
        color: ${theme('colors.azureBlue')};
      }
    }
  }
`;

const SocialMediaSection = styled.div`
  display: flex;
  flex-wrap: wrap;

  ${down('sm')} {
    margin-bottom: 1rem;
  }

  ${between('md', 'xl')} {
    justify-content: center;
  }

  ${between('lg', 'xl')} {
    max-width: 90%;
  }

  .media-icon {
    background-color: ${theme('colors.white')};
    border: 0.0625rem solid ${theme('colors.white')};
    border-radius: 50%;
    display: inline-block;
    flex: 1 0;
    margin: 0.125rem 0.3125rem;
    max-width: 2.75rem;

    ${up('md')} {
      min-width: 2.5rem;
    }

    ${up('xl')} {
      min-width: auto;
    }

    &:first-child {
      ${up('xl')} {
        margin-left: 0;
      }
    }

    &:last-child {
      ${up('xl')} {
        margin-left: 0;
      }
    }
  }

  img {
    width: 100%;
  }
`;

const propTypes = {
  data: PropTypes.shape({
    prismic: PropTypes.shape({
      allFooters: PropTypes.shape({
        edges: PropTypes.arrayOf(
          PropTypes.shape({
            node: PropTypes.shape({
              aboutDough: richTextShape,
              disclosure: richTextShape,
              title: PropTypes.array,
              socialMedias: socialMediaShape,
              appDownloads: PropTypes.arrayOf(
                PropTypes.shape({
                  image: imageShape,
                  link: linkShape,
                })
              ),
            }),
            body: PropTypes.arrayOf(
              PropTypes.shape({
                primary: PropTypes.shape({
                  header: richTextShape,
                }),
                fields: PropTypes.arrayOf(
                  PropTypes.shape({
                    footerLinkText: PropTypes.string,
                    footerLink: linkShape,
                    footerLinkSecondary: linkShape,
                  })
                ),
              })
            ),
          })
        ),
      }),
    }),
  }),
};

function Footer({ data }) {
  const doc = data.prismic.allFooters.edges.slice(0, 1).pop();

  if (!doc) {
    return null;
  }

  const node = doc.node;
  const socialMedias = node.socialMedias;

  return (
    <StyledFooter>
      <FooterContainer>
        <StyledRow>
          {node.body.map((bodySection) => (
            <Col xs={6} md={3} key={first(bodySection.primary.header).text}>
              <SectionTitle>
                {first(bodySection.primary.header).text}
              </SectionTitle>
              <FooterLinkList items={bodySection.fields} />
            </Col>
          ))}
          <Col sm={12} md={3}>
            <SectionTitle>Follow dough</SectionTitle>
            <SocialMediaSection>
              {socialMedias &&
                socialMedias.socialMedias.map((media, index) => {
                  if (!media.show || !media.link || !media.icon) {
                    return null;
                  }

                  return (
                    <PrismicLink
                      key={`social-media-icon-${index}`}
                      link={media.link}
                      internalAs={Link}
                      internalProps={{ as: Link }}
                      externalAs='a'
                      externalProps={{
                        target: '_blank',
                      }}
                      className={'media-icon'}
                    >
                      <img
                        src={media.icon.url}
                        alt={
                          media.icon.alt
                            ? media.icon.alt
                            : get(first(media.name), 'text')
                        }
                      />
                    </PrismicLink>
                  );
                })}
            </SocialMediaSection>
          </Col>
          <Col sm={12} md={3}>
            <RichText render={node.aboutDough} />
            <AppDownloadContainer>
              {node.appDownloads.map((appDownload, index) => (
                <AppDownload
                  appLogo={appDownload.image}
                  downloadLink={appDownload.link}
                  key={`appDownload-${index}`}
                />
              ))}
            </AppDownloadContainer>
          </Col>
        </StyledRow>
        <CopyrightSection>
          <RichText render={node.disclosure} />
        </CopyrightSection>
      </FooterContainer>
    </StyledFooter>
  );
}

Footer.propTypes = propTypes;

const footerQuery = graphql`
  query {
    prismic {
      allFooters {
        edges {
          node {
            aboutDough: about_dough_blurb
            disclosure: footer_disclosure
            title: footer_title
            socialMedias: footer_social_medias {
              ...SocialMediaFragment
            }
            appDownloads: footer_app_downloads {
              image: app_download_image
              link: app_download_link {
                ...LinkFragment
              }
            }
            body {
              ...FooterMenuSliceFragment
            }
          }
        }
      }
    }
  }
`;

export default (props) => (
  <StaticQuery
    query={footerQuery}
    render={withPreview(
      (data) => (
        <Footer data={data} {...props} />
      ),
      footerQuery
    )}
  />
);
