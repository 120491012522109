import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import get from 'lodash/get';
import theme from 'styles/theme';
import styled, { ThemeProvider, css } from 'styled-components/macro';
import GlobalStyle from 'styles/GlobalStyle';
import Header from './Header';
import Footer from './Footer';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorBoundaryPage from './ErrorBoundaryPage';
import FloatingCta from './FloatingCta';
import 'styles/bootstrap.scss';
import { ifProp, prop } from 'styled-tools';

const MainSection = styled.main`
  flex: 1 0 auto;

  ${ifProp(
    'pageBackgroundColor',
    css`
      background-color: ${prop('pageBackgroundColor')};
    `
  )}

  ${ifProp(
    'pageBodyTextColor',
    css`
      color: ${prop('pageBodyTextColor')};
    `
  )}
`;

const Layout = ({ pageStyles = {}, children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  const siteName = get(data, 'site.siteMetadata.title');

  return (
    <ThemeProvider theme={{ ...theme, pageStyles }}>
      <GlobalStyle />
      <ErrorBoundary FallbackComponent={ErrorBoundaryPage}>
        <Header />
        <MainSection {...pageStyles}>{children}</MainSection>
        <Footer />
        <FloatingCta />
      </ErrorBoundary>
    </ThemeProvider>
  );
};

Layout.propTypes = {
  pageStyles: PropTypes.shape({
    pageBackgroundColor: PropTypes.string,
    pageBodyTextColor: PropTypes.string,
  }),
  children: PropTypes.node.isRequired,
};

export default Layout;
