import React from 'react';
import styled from 'styled-components/macro';
import { graphql, StaticQuery } from 'gatsby';
import { withPreview } from 'gatsby-source-prismic-graphql';
import PropTypes from 'prop-types';
import { isAndroid, isIOS, isMobileOnly } from 'react-device-detect';
import get from 'lodash/get';
import PrismicLink from 'components/PrismicLink';
import { Link } from 'gatsby';
import { prop, theme } from 'styled-tools';
import appDownloadLinksDataShape from 'shapes/appDownloadLinksDataShape';
import DownloadSection from 'components/FloatingCta/DownloadSection';
import prismicImageShape from 'shapes/prismicImageShape';

const StickyFooter = styled.div`
  height: 4rem;
  position: sticky;
  bottom: 0;
  background-color: ${prop('theme.colors.black')};

  a {
    height: inherit;
  }
`;

const appDownloadTextQuery = graphql`
  query FloatingCtaAppDownloadQuery {
    prismic {
      allFloating_ctas {
        edges {
          node {
            ...FloatingCtaFragment
          }
        }
      }
      allSocial_medias {
        edges {
          node {
            ...AppDownloadLinksFragment
          }
        }
      }
    }
  }
`;

const propTypes = {
  data: PropTypes.shape({
    prismic: PropTypes.shape({
      allFloating_ctas: PropTypes.shape({
        edges: PropTypes.arrayOf(
          PropTypes.shape({
            node: PropTypes.shape({
              downloadButtonText: PropTypes.string,
              buttonStyle: PropTypes.string,
              appLogo: prismicImageShape,
            }),
          })
        ),
      }),
      allSocial_medias: PropTypes.shape({
        edges: PropTypes.arrayOf(
          PropTypes.shape({
            node: appDownloadLinksDataShape,
          })
        ),
      }),
    }),
  }),
};

function FloatingCta({ data }) {
  const floatingCta = get(data, 'prismic.allFloating_ctas.edges[0].node', {});

  const appDownloadLinks = get(
    data,
    'prismic.allSocial_medias.edges[0].node',
    {}
  );

  const { downloadButtonText, buttonStyle, appLogo } = floatingCta;

  const {
    appStoreImage,
    appStoreLink,
    googlePlayLink,
    googlePlayImage,
  } = appDownloadLinks;

  return (
    <>
      {isMobileOnly && (
        <StickyFooter>
          {isIOS && appStoreLink && appStoreImage && (
            <DownloadSection
              downloadLink={appStoreLink}
              appLogo={appLogo}
              downloadButtonText={downloadButtonText}
              buttonStyle={buttonStyle}
            />
          )}
          {isAndroid && googlePlayLink && googlePlayImage && (
            <DownloadSection
              downloadButtonText={downloadButtonText}
              downloadLink={googlePlayLink}
              appLogo={appLogo}
              buttonStyle={buttonStyle}
            />
          )}
        </StickyFooter>
      )}
    </>
  );
}

FloatingCta.propTypes = propTypes;

export default (props) => (
  <StaticQuery
    query={appDownloadTextQuery}
    render={withPreview(
      (data) => (
        <FloatingCta data={data} {...props} />
      ),
      appDownloadTextQuery
    )}
  />
);
